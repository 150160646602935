import {Destination} from '@wix/wixstores-graphql-schema-node';
import {getCountryShortKey} from './localeDataset.util';
import {CountryCode} from '@wix/form-fields';

export const getEnabledDeliveryCountries = (destinations?: Destination[]): CountryCode[] => {
  let isAsteriskFound = false;
  const enabledDeliveryCountries = Array.from(
    new Set(
      (destinations ?? []).flatMap((destination) =>
        destination.locations.map((location) => {
          if (location.code === '*') {
            isAsteriskFound = true;
            return '*';
          } else {
            return getCountryShortKey(location.code);
          }
        })
      )
    )
  ) as CountryCode[];
  return isAsteriskFound ? [] : enabledDeliveryCountries;
};
